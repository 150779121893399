import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, of, tap } from 'rxjs';
import {
  Dashboard,
  DashboardComponent,
  DashboardImageToUpload,
  DashboardOrder,
  DashboardOrderRaw,
  DashboardPrivacy
} from '../../home/features/dashboard/models/dashboard.model';
import { TimeZoneHelper } from '@core/helpers/timeZone.helper';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private baseUrl: string = environment.apiUrl + '/dashboard';
  private imageBank: { [key: string]: Blob } = {};

  constructor(private http: HttpClient) {}

  public getDashboards(user: string, admin: boolean, date: string, requested: string): Observable<Array<Dashboard>> {
    const timeZone = TimeZoneHelper.getLocalTimeZone();
    return this.http.post<Array<Dashboard>>(`${this.baseUrl}/list`, {
      date: date,
      timeZone,
      simulationType: 0,
      userEmail: user,
      adminUser: admin,
      requested: requested ? requested : undefined
    });
  }
  public getPublics(): Observable<Array<Dashboard>> {
    return this.http.get<Array<Dashboard>>(`${this.baseUrl}/public`);
  }

  public getImage(imageId: string): Observable<Blob> {
    if (this.imageBank[imageId]) {
      return of(this.imageBank[imageId]);
    } else {
      return this.http
        .get<Blob>(`${this.baseUrl}/getImage/${imageId}`, {
          observe: 'body',
          responseType: 'blob' as 'json'
        })
        .pipe(
          tap(image => {
            this.imageBank[imageId] = image;
          })
        );
    }
  }

  public removeImages(imagesId: string[]): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/deleteImages`, imagesId);
  }

  public removeDashboard(id: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`);
  }

  public getDashboardDetail(user: string, date: string, id: string): Observable<Dashboard> {
    const timeZone = TimeZoneHelper.getLocalTimeZone();
    return this.http.post<Dashboard>(`${this.baseUrl}/detail`, {
      id,
      date: date,
      timeZone,
      simulationType: 0,
      userEmail: user
    });
  }

  public previewComponent(user: string, date: string, component: DashboardComponent): Observable<DashboardComponent> {
    const timeZone = TimeZoneHelper.getLocalTimeZone();
    return this.http.post<DashboardComponent>(`${this.baseUrl}/preview`, {
      component: this.cleanDashboardComponent(component),
      date: date,
      timeZone,
      simulationType: 0,
      userEmail: user
    });
  }

  public upsertDashboard(
    userEmail: string,
    userName: string,
    date: string,
    dashboard: Dashboard,
    images: DashboardImageToUpload[]
  ): Observable<Dashboard> {
    const formData = new FormData();
    const timeZone = TimeZoneHelper.getLocalTimeZone();

    images.forEach(imageData => {
      formData.append('images', imageData.image, imageData.componentId.toString());
    });

    formData.append(
      'request',
      JSON.stringify({
        dashboard: this.cleanDashboard(dashboard),
        date: date,
        timeZone,
        simulationType: 0,
        userEmail,
        userName
      })
    );

    return this.http.post<Dashboard>(`${this.baseUrl}/save`, formData);
  }

  public publishDashboard(user: string, id: string, privacy: DashboardPrivacy): Observable<DashboardPrivacy> {
    const timeZone = TimeZoneHelper.getLocalTimeZone();
    return this.http.post<DashboardPrivacy>(`${this.baseUrl}/publish`, {
      id,
      privacy,
      timeZone,
      userEmail: user
    });
  }

  public draftDashboard(id: string, currentlyDraftBy: string): Observable<Dashboard> {
    return this.http.post<Dashboard>(`${this.baseUrl}/draft`, {
      id,
      currentlyDraftBy
    });
  }

  private cleanDashboard(dashboard: Dashboard): Dashboard {
    return { ...dashboard, components: dashboard.components.map(comp => this.cleanDashboardComponent(comp)) };
  }

  private cleanDashboardComponent(component: DashboardComponent): DashboardComponent {
    return {
      ...component,
      curve: component.curve ? { ...component.curve, data: undefined, chart: undefined } : undefined,
      path: component.path ? { ...component.path, data: undefined } : undefined,
      digitalMeter: component.digitalMeter ? { ...component.digitalMeter, data: undefined } : undefined
    };
  }

  public getDashboardOrders(user: string): Observable<DashboardOrderRaw> {
    return this.http.post<DashboardOrderRaw>(`${this.baseUrl}/order`, { email: user, dashboards: {} });
  }
  public setDashboardOrders(orders: DashboardOrder): Observable<DashboardOrderRaw> {
    return this.http.put<DashboardOrderRaw>(`${this.baseUrl}/order`, orders);
  }
}
